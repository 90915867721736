import { Helmet } from "react-helmet";
import PropTypes from "prop-types";
import React from "react";

import { SiteLayout } from "./src/components/SiteLayout";
import { Theme } from "./src/components/Theme";

export const wrapPageElement = ({ element }) => {
  return (
    <Theme>
      <Helmet>
        <link rel="icon" type="image/png" sizes="128x128" href="/favicon.ico" />
        <link rel="icon" type="image/png" sizes="512x512" href="/icon.png" />
      </Helmet>
      <SiteLayout>{element}</SiteLayout>
    </Theme>
  );
};

wrapPageElement.propTypes = {
  element: PropTypes.element.isRequired,
  props: PropTypes.object.isRequired,
};
