import { wrapPageElement } from "./wrap-element";

function handleAnalyticCookieConsentChange(consentChangeEvent) {
  consentChangeEvent.preventDefault();

  // check it user has consented to analytics cookies
  if (window.Cookiebot?.consent?.statistics) {
    // disable google analytics if statistics consent is given
    window["ga-disable-GA_MEASUREMENT_ID"] = false;
  } else {
    // enable google analytics if statistics consent is withdrawn
    window["ga-disable-GA_MEASUREMENT_ID"] = true;
  }
}

export const onClientEntry = async () => {
  if (typeof IntersectionObserver === "undefined") {
    await import("intersection-observer");
  }

  if (typeof window !== "undefined" && window?.Cookiebot) {
    // initialize Cookiebot event listeners
    window.addEventListener(
      "CookiebotOnLoad",
      handleAnalyticCookieConsentChange
    );

    window.addEventListener(
      "CookiebotOnAccept",
      handleAnalyticCookieConsentChange
    );

    window.addEventListener(
      "CookiebotOnDecline",
      handleAnalyticCookieConsentChange
    );
  }
};

export { wrapPageElement };
