import React, { useEffect } from "react";
import _merge from "lodash/merge";
import defaultTheme from "./default-theme";
import { ThemeProvider } from "styled-components";
import { Fragment } from "react";
import DefaultGlobalStyles from "./default-global-styles";
import PropTypes from "prop-types";
import AOS from "aos";
import "aos/dist/aos.css";

export function theme(parentThemeContext) {
  return _merge({}, defaultTheme, parentThemeContext);
}

export function Theme({ children }) {
  useEffect(() => {
    AOS.init({
      animatedClassName: "aos-animate",
      initClassName: "aos-init",
    });
  }, []);

  useEffect(() => {
    // AOS in combination with react sometimes does not initial component during
    // resize. So we need do it by myself using "AOS.refreshHard()" that reinit
    // array with AOS elements.

    let refreshTimer;
    let windowWidth;
    function debounceHardRefresh() {
      // Avoid triggering resizing function on mobile when width is not changing
      if (window.innerWidth !== windowWidth) {
        windowWidth = window.innerWidth;
        clearTimeout(refreshTimer);
        refreshTimer = setTimeout(AOS.refreshHard, 100);
      }
    }

    window.addEventListener("resize", debounceHardRefresh);
    return () => window?.removeEventListener("resize", debounceHardRefresh);
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Fragment>
        <DefaultGlobalStyles />
        {children}
      </Fragment>
    </ThemeProvider>
  );
}

Theme.prototype = {
  children: PropTypes.any.isRequired,
};
