import React, {
  useState,
  useRef,
  useEffect,
  useCallback,
  forwardRef,
} from "react";
import { Container, StyledInput, SearchModal, SearchOption } from "./styles";

const Input = forwardRef(
  (
    {
      reset,
      options = [],
      onChangeCallback,
      disabled = false,
      defaultValue,
      ...rest
    },
    ref
  ) => {
    const defaultInputRef = useRef(null);
    const inputRef = ref || defaultInputRef;
    const dropdownRef = useRef(null);
    const [value, setValue] = useState("");
    const [isTouched, setIsTouched] = useState(false);
    const [isModal, setIsModal] = useState(false);

    const resetHandler = () => {
      setValue("");
      setIsTouched(false);
      setIsModal(false);
    };

    useEffect(() => resetHandler(), [reset]);

    useEffect(() => {
      defaultValue && setValue(defaultValue);
    }, [defaultValue]);

    const inputChangeValidation = useCallback(
      (_value) => {
        const inputFieldElement = inputRef?.current || null;
        if (!inputFieldElement || !_value.length) {
          return;
        }

        if (options?.length && options?.includes(_value) === false) {
          inputFieldElement.setCustomValidity(
            "Please enter a value from the options listed"
          );
        } else {
          inputFieldElement.setCustomValidity("");
        }
        inputFieldElement.reportValidity();

        return;
      },
      [options, inputRef]
    );

    useEffect(() => {
      disabled && setValue("");
    }, [disabled]);

    useEffect(() => {
      inputChangeValidation(value);
      onChangeCallback?.(value);
    }, [inputChangeValidation, onChangeCallback, value]);

    const handleOnChange = (evt) => {
      !isTouched && setIsTouched(true);
      options?.length && !isModal && setIsModal(true);
      setValue(evt.target.value);
    };

    const handleOnClick = (evt) => {
      evt.preventDefault();
      if (options?.length) {
        setValue("");
        setIsModal(!isModal);
      }
    };

    const handleSelectOption = (option) => {
      setValue(option);
      setIsModal(!isModal);
    };

    const handleOnBlurEvent = (event) =>
      (![...(dropdownRef.current?.children ?? [])].includes(
        event.relatedTarget
      ) &&
        setIsModal(false)) ||
      null;

    return (
      <Container>
        <StyledInput
          disabled={disabled}
          autoComplete="off"
          ref={inputRef}
          value={value}
          onChange={handleOnChange}
          onClick={handleOnClick}
          onBlur={handleOnBlurEvent}
          is_touched={isTouched}
          {...rest}
        />
        {isModal && (
          <SearchModal ref={dropdownRef}>
            {options
              .filter((option) =>
                option.toUpperCase().includes(value.toUpperCase())
              )
              .map((option, idx) => {
                return (
                  <SearchOption
                    key={`${rest.name}-${option}-${idx}`}
                    tabIndex="0"
                    onClick={() => handleSelectOption(option)}
                  >
                    {option}
                  </SearchOption>
                );
              })}
          </SearchModal>
        )}
      </Container>
    );
  }
);

export default Input;
