import NotebookOutline from "../../static/icons/NotebookOutline.svg";
import ScienceOutline from "../../static/icons/ScienceOutline.svg";
import SoftwareOutline from "../../static/icons/SoftwareOutline.svg";
import User from "../../static/icons/User.svg";
import Search from "../../static/icons/Search.svg";
import Basket from "../../static/icons/Basket.svg";
import Logo from "../../static/icons/Logo.svg";
import HamburgerOpened from "../../static/icons/HamburgerOpened.svg";
import HamburgerClosed from "../../static/icons/HamburgerClosed.svg";
import SavantPower from "../../static/icons/savant-power.svg";
import Instagram from "../../static/icons/Instagram.svg";
import Youtube from "../../static/icons/Youtube.svg";
import Facebook from "../../static/icons/Facebook.svg";
import Twitter from "../../static/icons/Twitter.svg";
import Play from "../../static/icons/Play.svg";
import OrangeTriangle from "../../static/icons/OrangeTriangle.svg";
import ChevronLeft from "../../static/icons/ChevronLeft.svg";
import ChevronRight from "../../static/icons/ChevronRight.svg";
import DropDown from "../../static/icons/DropDown.svg";
import SavantAmbassador from "../../static/icons/SavantAmbassador.svg";
import SavantGold from "../../static/icons/SavantGold.svg";
import SavantPlatinum from "../../static/icons/SavantPlatinum.svg";
import SavantPlatinumElite from "../../static/icons/SavantPlatinumElite.svg";
import SavantSilver from "../../static/icons/SavantSilver.svg";
import SavantAuthorized from "../../static/icons/SavantAuthorized.svg";
import SavantCertifiedReseller from "../../static/icons/SavantCertifiedReseller.svg";
import SavantContractor from "../../static/icons/SavantContractor.svg";
import Point from "../../static/icons/point.svg";
import CheckMark from "../../static/icons/check_mark.svg";
import Spinner from "../../static/icons/Spinner.svg";
import CheckCircleOutline from "../../static/icons/CheckCircleOutline.svg";
import CircularLeftArrow from "../../static/icons/ArrowLeft.svg";
import Close from "../../static/icons/Close.svg";
import Home from "../../static/icons/Home.svg";
import Support from "../../static/icons/Support.svg";
import TriangleRightFilled from "../../static/icons/TriangleRightFilled.svg";
import EmailSubscribe from "../../static/icons/EmailSubscribe.svg";

import { NONE } from "./input";

export const IconMap = {
  NotebookOutline,
  ScienceOutline,
  SoftwareOutline,
  User,
  Search,
  Basket,
  Logo,
  HamburgerOpened,
  HamburgerClosed,
  Play,
  SavantPower,
  Twitter,
  Facebook,
  Instagram,
  Youtube,
  OrangeTriangle,
  ChevronRight,
  ChevronLeft,
  DropDown,
  SavantAmbassador,
  SavantGold,
  SavantPlatinum,
  SavantPlatinumElite,
  SavantSilver,
  SavantAuthorized,
  SavantCertifiedReseller,
  SavantContractor,
  Point,
  CheckMark,
  Spinner,
  CheckCircleOutline,
  CircularLeftArrow,
  Close,
  Home,
  Support,
  TriangleRightFilled,
  EmailSubscribe,
  [NONE]: null,
};

export const iconOptions = Object.keys(IconMap);
