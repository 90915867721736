import React, { useMemo, useState } from "react";
import {
  Container,
  Content,
  Copyright,
  ExternalLink,
  Flex,
  FormRow,
  FormSubmitButton,
  FormTextInput,
  IconImage,
  InternalLink,
  Legal,
  SectionLabel,
  FormGroup,
  SubscribeTitle,
  Column,
  Grid,
} from "./styles";

import { REGEX_VALIDATION_PATTERNS } from "consts/validations";
import { requestURL, requestOptions } from "../BlogPostTiles/constants";
import {
  CardContent,
  FormContentContainer,
  SubscribeSubtitle,
} from "../BlogPostTiles/styles";
import { learnMoreBtnType } from "../LearnMoreBtn";

const currentYear = new Date().getFullYear();

function LinkComponent({ link, className, children }) {
  let Comp = <></>;
  const props = { className };

  if (link?.[0] === "/") {
    props.to = link;
    Comp = InternalLink;
  } else {
    props.href = link;
    props.target = "_blank";
    Comp = ExternalLink;
  }

  return <Comp {...props}>{children}</Comp>;
}

function LinkSection({ label, links }) {
  return (
    <Column className="links-section">
      <SectionLabel className="section-label">{label}</SectionLabel>
      {links?.map(({ footerBarLinkLabel, footerBarSectionLink }, idx) => (
        <LinkComponent
          key={`link-section-${idx}`}
          link={footerBarSectionLink}
          className="section-link"
        >
          {footerBarLinkLabel}
        </LinkComponent>
      ))}
    </Column>
  );
}

export default function FooterComponent({
  background,
  fontColor,
  footerBarLinkSections = [],
  footerBarIconButtons = [],
}) {
  const [showSubmitSuccess, setShowSubmitSuccess] = useState(false);

  const getCurrentPageInfo = () => {
    if (typeof window !== "undefined") {
      const pageUri = window.location.href;
      let pageName = window.location.pathname;

      if (pageName?.length === 1 && pageName === "/") {
        pageName = "Homepage";
      } else {
        pageName = pageName.slice(1);
      }

      return {
        pageName,
        pageUri,
      };
    }
  };

  const linkSectionPercentColWidth =
    100 - (1 / footerBarLinkSections.length + 1) * 100;
  const linkSectionContent = useMemo(
    () =>
      footerBarLinkSections?.map(
        ({ footerBarSectionLabel = "", footerBarSectionLinks = [] }, idx) => (
          <LinkSection
            key={`link-section-${idx}`}
            label={footerBarSectionLabel}
            links={footerBarSectionLinks}
          />
        )
      ),
    [footerBarLinkSections]
  );

  async function submitForm(event) {
    event.preventDefault();
    const formData = new FormData(event.target);
    const formattedData = [...formData].reduce((acc, [key, value]) => {
      if (key === "email") {
        return {
          ...acc,
          fields: [
            {
              name: key,
              value,
            },
          ],
        };
      }

      return acc;
    }, {});

    try {
      const response = await fetch(requestURL, {
        ...requestOptions,
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          "LEGAL_CONSENT.subscription_type_8098782": true,
          context: {
            hutk: "71984246eae633afb1ca20e903d7b50b",
            ...getCurrentPageInfo(),
          },
          ...formattedData,
        }),
      });

      if (!response.ok) {
        throw new Error(`Error: ${response.status}`);
      }

      setShowSubmitSuccess(true);
    } catch (err) {
      console.error("Submission Failed", err.message);
      setShowSubmitSuccess(false);
    }
  }

  const formContent = (
    <>
      <iframe
        title="sf-blog-subscribe"
        id="sf-blog-subscribe"
        name="sf-blog-subscribe"
        src="about:blank"
        style={{ display: "none" }}
      />
      <CardContent className="form-card">
        <FormContentContainer onSubmit={submitForm}>
          <input
            type="hidden"
            name="LEGAL_CONSENT.subscription_type_8098782"
            value="true"
          />
          {!showSubmitSuccess ? (
            <FormGroup>
              <SubscribeTitle>Join our newsletter</SubscribeTitle>
              <FormRow>
                <FormTextInput
                  placeholder="email*"
                  pattern={REGEX_VALIDATION_PATTERNS.EMAIL}
                  id="email"
                  name="email"
                  type="email"
                  required={true}
                />
                <FormSubmitButton
                  classNAme="form-submit"
                  isSubmit={true}
                  btnType={learnMoreBtnType.ghostBlackBtn}
                >
                  Submit
                </FormSubmitButton>
              </FormRow>
            </FormGroup>
          ) : (
            <FormGroup>
              <SubscribeSubtitle style={{ margin: 0 }}>
                Thank you for subscribing to our newsletter!
              </SubscribeSubtitle>
            </FormGroup>
          )}
        </FormContentContainer>
      </CardContent>
    </>
  );

  const iconButtonsSection = (
    <>
      {footerBarIconButtons?.map(
        ({ footerBarNavButtonIcon, footerBarNavButtonUrl }, idx) => {
          return (
            <LinkComponent
              link={footerBarNavButtonUrl}
              className="icon-button-link"
              key={`footerIconButtons-${idx}`}
            >
              <IconImage item={footerBarNavButtonIcon} />
            </LinkComponent>
          );
        }
      )}
    </>
  );

  return (
    <Container
      bg_color={background}
      font_color={fontColor}
      link_section_pct_col_width={linkSectionPercentColWidth}
    >
      <Content>
        <Column className="left-col">
          <Grid className="link-section-container">{linkSectionContent}</Grid>
          <Flex className="form-subscribe-container">{formContent}</Flex>
        </Column>
        <Column className="right-col">
          <Flex className="app-links-container">{iconButtonsSection}</Flex>
        </Column>
      </Content>
      <Legal>
        <p>
          By subscribing above, you agree to receive marketing communications
          from Savant.
        </p>
        <br />
        <Copyright>
          <p>
            Copyright 2014–{currentYear} Savant Systems, Inc | *The GE monogram
            is a trademark of General Electric Company used under license.
          </p>
        </Copyright>
      </Legal>
    </Container>
  );
}
