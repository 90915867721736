import { createGlobalStyle } from "styled-components";

const GlobalStyles = createGlobalStyle`
  @font-face {
    font-family: Gotham;
    src: url("https://cdn.savant.com/fonts/Gotham-Light.woff") format("woff");
    font-weight: 300;
    font-display: swap;
  }

  @font-face {
    font-family: Gotham;
    src: url("https://cdn.savant.com/fonts/Gotham-Book.woff") format("woff");
    font-weight: 400;
    font-display: swap;
  }

  @font-face {
    font-family: Gotham;
    src: url("https://cdn.savant.com/fonts/Gotham-Medium.woff") format("woff");
    font-weight: 500;
    font-display: swap;
  }

  @font-face {
    font-family: Gotham;
    src: url("https://cdn.savant.com/fonts/Gotham-Bold.woff") format("woff");
    font-weight: 700;
    font-display: swap;
  }

  html,
  body {
    height: 100%;
    width: 100%;
    background-color: #fff !important;
    font-family: Gotham, sans-serif;
    font-weight: 300;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  html {
    text-rendering: optimizeLegibility;
    scroll-padding-top: 120px; /* height of sticky header */
  }

  a {
    color: inherit;
  }

  * {
    box-sizing: border-box;
  }

  h1, h2, h3, h4, h5, h6 {
    font-weight: normal;
  }
`;

export default GlobalStyles;
