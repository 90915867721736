import React from "react";
import { graphql, StaticQuery } from "gatsby";
import FooterComponent from "./component";

const Footer = ({ currentPath }) => (
  <StaticQuery
    query={query}
    render={({ markdownRemark }) => {
      return (
        <FooterComponent
          {...(markdownRemark?.frontmatter ?? {})}
          currentPath={currentPath}
        />
      );
    }}
  />
);

const query = graphql`
  query FooterQuery {
    markdownRemark(fileAbsolutePath: { glob: "**/appWidgets/footerBar/**" }) {
      frontmatter {
        background
        fontColor
        footerBarLinkSections {
          footerBarSectionLabel
          footerBarSectionLinks {
            footerBarLinkIcon {
              id
              publicURL
              childImageSharp {
                gatsbyImageData
              }
            }
            footerBarLinkLabel
            footerBarSectionLink
          }
        }
        footerBarIconButtons {
          footerBarNavButtonIcon {
            id
            publicURL
            childImageSharp {
              gatsbyImageData(quality: 100)
            }
          }
          footerBarNavButtonUrl
        }
        footerBarLegalSection {
          footerBarLegalText
        }
      }
    }
  }
`;

export default Footer;
