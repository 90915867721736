import pkg from "../../../package.json";

export const learnMoreBtnType = {
  orangeBtn: "orangeBtn",
  fullOrangeBtn: "fullOrangeBtn",
  whiteBtn: "whiteBtn",
  ghostBlackBtn: "ghostBlackBtn",
  textLink: "textLink",
  orangeLink: "orangeLink",
  blackTextLink: "blackTextLink",
  whiteWithBrownBorder: "whiteWithBrownBorder",
  powerGreenOutline: "powerGreenOutline",
  greenLink: "greenLink",
};

export const defaultBtnType =
  pkg?.name === "savpow"
    ? learnMoreBtnType.powerGreenOutline
    : learnMoreBtnType.fullOrangeBtn;
