import React from "react";
import { learnMoreBtnType, defaultBtnType } from "./constants";
import { ExternalLink, LocalLink, SampleLink, SubmitButton } from "./styles";
import { isLocalLink } from "../../utils/isLocalLink";

const LearnMoreBtn = ({
  className,
  children,
  btnUrl,
  btnType,
  openInNewTab,
  onClick,
  isSubmit,
}) => {
  const _btnType = btnType || defaultBtnType;

  if (!btnUrl && !onClick && !isSubmit) {
    return null;
  }

  const label = children || "Learn More";

  if (btnUrl === "#")
    return (
      <SampleLink className={className} btntype={_btnType}>
        {label}
      </SampleLink>
    );

  if (isLocalLink(btnUrl) && !openInNewTab) {
    return (
      <LocalLink className={className} to={btnUrl} btntype={_btnType}>
        {label}
      </LocalLink>
    );
  }

  if (onClick) {
    return (
      <SampleLink className={className} onClick={onClick} btntype={_btnType}>
        {label}
      </SampleLink>
    );
  }

  if (isSubmit) {
    return (
      <SubmitButton className={className} btntype={_btnType} htmlType="submit">
        {label}
      </SubmitButton>
    );
  }

  return (
    <ExternalLink
      className={className}
      href={btnUrl}
      btntype={_btnType}
      target={openInNewTab ? "_blank" : null}
    >
      {label}
    </ExternalLink>
  );
};

export { learnMoreBtnType, defaultBtnType };

export default LearnMoreBtn;
