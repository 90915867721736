import styled, { css } from "styled-components";
import { media } from "../Theme/default-theme";
import Link from "gatsby-link";
import { learnMoreBtnType } from "./constants";

const commonStyles = css`
  background: transparent;
  display: inline-block;
  margin-top: 23px;
  padding: 8px 14.5px;
  font-style: normal;
  font-weight: normal;
  font-size: clamp(0.75rem, 0.6324rem + 0.5878vw, 1.25rem);
  line-height: 125%;
  text-align: center;
  color: #ff5f00;
  text-transform: uppercase;
  text-decoration: none;
  cursor: pointer;
  border: 1px solid #ff5f00;
  box-sizing: border-box;
  border-radius: 4px;
  transition: all 0.35s ease-out;

  ${media.tablet} {
    margin-top: 52px;
    padding: 15px 25px;
  }

  ${media.desktop} {
    padding: 16px 28px;
  }
`;

const linkStyles = css`
  border: none;
  text-decoration-line: underline;
  text-transform: uppercase;
  padding: 0 !important;

  &:hover {
    background: none;
  }
`;

const whiteLinkStyles = css`
  ${linkStyles};
  color: #fff;

  &:hover {
    color: #ff5f00;
  }
`;

const orangeLinkStyles = css`
  ${linkStyles};
  text-transform: initial;
  color: #ff5f00;

  &:hover {
    text-decoration: none;
  }
`;

const greenLinkStyles = css`
  ${linkStyles};
  text-transform: initial;
  color: #03ad95;

  &:hover {
    text-decoration: none;
  }
`;

const blackLinkStyles = css`
  ${linkStyles};
  color: #696057;

  &:hover {
    color: #ff5f00;
  }
`;

const whiteBtnStyles = css`
  border: 1px solid #fff;
  color: #fff;

  &:hover {
    background: rgba(67, 68, 70, 0.6);
    border: 1px solid #9f9f9f;
  }
`;

const ghostBlackBtnStyles = css`
  color: #382e2b;
  border: 1px solid #382e2b;

  &:hover {
    background: rgba(67, 68, 70, 0.6);
  }
`;

const solidOrangeBtnStyles = css`
  color: #fff;
  background: #ff5f00;
`;

const fullOrangeBtnStyles = css`
  &:hover {
    color: #fff;
    background: #ff5f00;
  }
`;

const whiteWithBrownBorder = css`
  color: #fff;
  border: 1px solid #696057;
`;

const powerGreenOutline = css`
  background: transparent;
  color: #03ad95;
  border: 1px solid #03ad95;
`;

const getLinkStyle = (linkType) => {
  switch (linkType) {
    case "orangeBackgroundWhiteTextNoHover":
      return solidOrangeBtnStyles;
    case learnMoreBtnType.whiteBtn:
      return whiteBtnStyles;
    case learnMoreBtnType.ghostBlackBtn:
      return ghostBlackBtnStyles;
    case learnMoreBtnType.textLink:
      return whiteLinkStyles;
    case learnMoreBtnType.orangeLink:
      return orangeLinkStyles;
    case learnMoreBtnType.blackTextLink:
      return blackLinkStyles;
    case learnMoreBtnType.orangeBtn:
    case learnMoreBtnType.fullOrangeBtn:
      return fullOrangeBtnStyles;
    case learnMoreBtnType.whiteWithBrownBorder:
      return whiteWithBrownBorder;
    case learnMoreBtnType.powerGreenOutline:
      return powerGreenOutline;
    case learnMoreBtnType.greenLink:
      return greenLinkStyles;
    default:
      return null;
  }
};

export const LocalLink = styled(Link)`
  ${commonStyles}
  ${({ btntype }) => getLinkStyle(btntype)}
`;

export const ExternalLink = styled.a`
  ${commonStyles}
  ${({ btntype }) => getLinkStyle(btntype)}
`;

export const SampleLink = styled.div`
  ${commonStyles}
  ${({ btntype }) => getLinkStyle(btntype)}
`;

export const SubmitButton = styled.button`
  ${commonStyles}
  ${({ btntype }) => getLinkStyle(btntype)}
`;
