import styled, { css } from "styled-components";
import { Link } from "gatsby";
import Image from "components/Image";
import ReactMarkdown from "react-markdown";
import { media } from "../Theme/default-theme";
import Input from "components/Input";
import LearnMoreBtn from "components/LearnMoreBtn";
import Icon from "components/Icon";

const ButtonStyle = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: center;
  font-family: Gotham, sans-serif;
  font-size: 16px;
  line-height: 125%;
  font-weight: 325;
  text-align: center;
  margin: 2em 0;
`;

export const PageSubtitle = styled.h5`
  line-height: 150%;
  font-style: normal;
  font-weight: 325;
  font-family: Gotham, sans-serif;
  font-size: clamp(0.875rem, 0.6252rem + 0.8326vw, 1.5rem);
  margin-top: 0.25em;
  text-align: center;
`;

export const PageTitle = styled.h3`
  font-size: clamp(1.3125rem, 1.0333rem + 1.396vw, 2.5rem);
  line-height: 150%;
  font-style: normal;
  font-weight: 325;
  font-family: Gotham, sans-serif;
  color: #382e2b;
  text-align: center;
  margin-bottom: 0.25em;
  text-align: center;
`;

export const PageTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: clamp(2rem, 0.7068rem + 6.4658vw, 7.5rem) auto;
  width: 85%;
`;

export const Markdown = styled(ReactMarkdown)`
  width: 100%;
  color: #382e2b;

  p {
    font-size: clamp(0.875rem, 0.6252rem + 0.8326vw, 1.25rem);
    line-height: 150%;
    font-style: normal;
    font-weight: 325;
    font-family: Gotham, sans-serif;
    margin-bottom: 0;
  }
`;

export const CardImage = styled(Image)`
  display: flex;
  flex: 1 1 50%;
  width: 100%;
  height: auto;
  aspect-ratio: 1;
`;

export const FormConsentText = styled.h6`
  font-size: 8px;
  line-height: 9.6px%;
  font-style: normal;
  font-weight: 325;
  font-family: Gotham, sans-serif;
  color: #382e2b;
  margin: 0;
`;

export const FormSubmitButton = styled(LearnMoreBtn)`
  ${ButtonStyle}
  width: 100%;
  height: 48px;
  margin: 0;
`;

export const FormTextInput = styled(Input)`
  display: flex;
  flex: 1;
  background: #fff;
  margin: 0;
  cursor: auto;
`;

export const FormRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin: 0;
`;

export const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 100%;
  row-gap: 1.3em;
  padding: 0;
`;

export const FormContentContainer = styled.form`
  display: flex;
  flex: 0 1 auto;
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: center;

  .subscribe-success-group {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 4em 0;
  }
`;

export const SubscribeTitle = styled.h2`
  line-height: 125%;
  font-style: normal;
  font-weight: 325;
  font-family: Gotham, sans-serif;
  color: #382e2b;
  text-align: center;
  font-size: 24px;
  margin: 1em 0;
`;

export const SubscribeSubtitle = styled.h4`
  line-height: 125%;
  font-style: normal;
  font-weight: 325;
  font-family: Gotham, sans-serif;
  color: #382e2b;
  text-align: center;
  font-size: 16px;
  margin: 0 0 1em;
`;

export const SubscribeIcon = styled(Icon)`
  height: 96px;
  width: 96px;
  aspect-ratio: 1;
  margin: 1.75em 0 0;
`;

export const FormHeaderContainer = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  background: #f6f6f6;
  align-items: center;
  flex-direction: column;
  margin-bottom: 1em;
  max-height: 277px;
  min-height: 277px;
  height: auto;
`;

export const CardContent = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: stretch;

  .blog-title {
    * {
      font-weight: 400 !important;
    }
  }
`;

export const SubscriptionCard = styled.div`
  cursor: default;
  width: 100%;
  height: auto;

  .form-card {
    height: 100%;
    justify-content: unset;
  }
`;

export const Card = styled(Link)`
  text-decoration: none;
  cursor: pointer;
  width: 100%;

  .form-card {
    height: 100%;
    justify-content: unset;
  }
`;

export const Content = styled.div`
  width: auto;
  height: auto;
  display: grid;
  grid-row-gap: clamp(2.1875rem, 1.0119rem + 5.878vw, 7.1875rem);
  grid-column-gap: 4rem;
  grid-template-columns: 1fr;
  max-width: 78%;
  margin: 0 auto;
`;

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: stretch;
  align-items: cetner;
  padding: 0 0 2em;

  ${media.tablet} {
    padding: 1.5em 0 5.25em;
    ${Content} {
      grid-template-columns: repeat(2, 1fr);
      max-width: 586px;

      .form-card {
        ${FormContentContainer} {
          .subscribe-success-group {
            display: flex;
            flex-direction: column;
            justify-content: center;
            margin: 0;
          }
        }
      }
    }
  }

  ${media.laptop} {
    padding: 0 0 7.5em;

    ${Content} {
      grid-template-columns: repeat(3, 1fr);
      max-width: 944px;

      .form-card {
        ${FormContentContainer} {
          .form-subscription-subtitle {
            font-size: 2em;
            margin: 1.125em 0 0.75em;
          }
        }
      }
    }
  }

  ${media.desktop} {
    ${Content} {
      grid-template-columns: repeat(4, 1fr);
      max-width: 1300px;
    }
  }
`;
