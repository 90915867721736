import React from "react";
import { GatsbyImage, NativeImage } from "./styles";

const Image = ({
  item = {},
  alt = "",
  className,
  objectFit = "cover",
  loading = "eager",
}) => {
  if (item?.childImageSharp?.gatsbyImageData) {
    return (
      <GatsbyImage
        className={className}
        image={item.childImageSharp.gatsbyImageData}
        alt={alt}
        objectFit={objectFit}
        loading={loading}
      />
    );
  }

  if (item?.publicURL) {
    return (
      <NativeImage
        className={className}
        src={item?.publicURL}
        alt={alt}
        object_fit={objectFit}
        loading={loading}
      />
    );
  }

  return null;
};

export default Image;
