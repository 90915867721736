import { appContainerId } from "@racepointenergy/gatsby-theme-sitespark/src/components/Html";
import PropTypes from "prop-types";
import React from "react";
import Footer from "components/Footer";

export const SiteLayout = ({ children }) => {
  return (
    <div id={appContainerId}>
      <div>{children}</div>
      <Footer />
    </div>
  );
};

SiteLayout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.element,
    PropTypes.elementType,
  ]),
  props: PropTypes.object,
};
