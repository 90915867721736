import React from "react";
import PropTypes from "prop-types";
import { IconMap, iconOptions } from "../../consts/icons";
import { NONE } from "../../consts/input";

const Icon = ({ className, type }) => {
  if (type === NONE || !iconOptions.includes(type)) {
    return null;
  }

  const Comp = IconMap[type];

  return (
    <i className={className}>
      <Comp />
    </i>
  );
};

Icon.propTypes = {
  className: PropTypes.string,
  type: PropTypes.string,
};

export default Icon;
